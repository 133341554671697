import {
  Box,
  Button,
  Center,
  CloseButton,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  Spacer,
} from "@chakra-ui/react";
import { Link as RRLink } from "react-router-dom";
import { PlusSquareIcon } from "@chakra-ui/icons";
import { Logo128 } from "../images";

export default function Uninstall(props) {
  return (
    <Container py={0} maxWidth={"container.2xl"}>
      <Flex h={70} p={5} align={"center"}>
        {/*<Heading size={"lg"}>AlphaBack</Heading>*/}
        <Link href={"https://alphaback.xyz"}>
          <Image src={Logo128} boxSize={"28px"} />
        </Link>
        <Spacer />
        <Link
          href={
            "https://chrome.google.com/webstore/detail/alpha-back/oecacmanfecanoehhmhmjmpdlhffiihe"
          }
        >
          <Button
            size={"md"}
            colorScheme={"brand"}
            leftIcon={<PlusSquareIcon />}
            variant={"outline"}
          >
            Reinstall extension
          </Button>
        </Link>
      </Flex>
      <Center>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSdnZmSyyL0WLUZpxS06rI89ieqYJKVhnJRepo2cpy1vs0pOxg/viewform?embedded=true"
          width="840"
          height="790"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        >
          Loading…
        </iframe>
      </Center>
    </Container>
  );
}
