import {
  Box, Button,
  Flex,
  Heading,
  Spacer,
} from '@chakra-ui/react'
import { CarouselCard, ItemForm } from "../components";
import { useEffect } from "react";
import { Bayc1 } from "../images";
import moment from "moment";
import useContract from "../hooks/useContract";
import { ethers } from "ethers";
import { useExecuteOrConnect, useItemObj } from "../hooks";
import { Link as RRLink } from 'react-router-dom'
import { ArrowLeftIcon, StarIcon } from '@chakra-ui/icons'
import { FaList } from 'react-icons/fa'

const { BigNumber, utils, constants } = ethers;
const { AddressZero } = constants;

export default function SubmitItem(props) {
  const [itemObj, dispatch] = useItemObj();
  const {
    title,
    description,
    imageUrl,
    upVotes,
    clickUrl,
    startDate,
    endDate,
    id,
  } = itemObj;
  const { reader, writer } = useContract("LeaderBoard");
  useEffect(() => {
    const initItem = async () => {
      const obj = {
        title: "Lorem Lips",
        description:
          "Amors credere in talis cubiculum! Voxs tolerare in mirabilis aetheres! Hercle, onus fidelis!, hydra!",
        imageUrl: Bayc1,
        upVotes: BigNumber.from(122),
        id: BigNumber.from(0),
        clickUrl: "https://opensea.io",
        startDate: moment().utc().startOf("day"),
        endDate: moment().utc().startOf("day").add(14, "days"),
      };
      dispatch({ type: "replace", obj });
    };
    initItem();
  }, []);
  const updateWantsToSubmit = useExecuteOrConnect(async () => {
    const obj = {
      submitter: AddressZero,
      imageUrl,
      clickUrl,
      title,
      description,
      startTime: startDate.unix(),
      endTime: endDate.unix(),
      upVotes: BigNumber.from(upVotes),
      id: BigNumber.from(id),
    };
    return await writer.addItem(obj);
  }, writer);
  return (
    <Box w={"full"}>
      <Flex direction={'row'} w={"full"}>
        {/*<Box>*/}
        {/*  <RRLink to={"/"}>*/}
        {/*    <Button leftIcon={<ArrowLeftIcon />}>Go Back</Button>*/}
        {/*  </RRLink>*/}
        {/*</Box>*/}
        <Heading size={"lg"} textAlign={"center"} flexGrow={1}>
          Submit a new Item
        </Heading>
        <Box>
          <RRLink to={"/view-all-items"}>
            <Button leftIcon={<FaList />}>View All Items</Button>
          </RRLink>
        </Box>
      </Flex>

      <Flex direction={"row"} mt={10}>
        <Spacer />
        <ItemForm
          dispatch={dispatch}
          itemObj={itemObj}
          submitHandler={updateWantsToSubmit}
        />
        <Spacer />
        <CarouselCard {...itemObj} />
        <Spacer />
      </Flex>
    </Box>
  );
}
