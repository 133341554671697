import { createContext, useContext, useEffect, useState } from "react";
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import { isAddress } from "ethers/lib/utils";
import { toHex } from "../helpers/utils";
import { networkParams } from "../helpers/networks";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import createMetaMaskProvider from "metamask-extension-provider";
import MixpanelContext from "./MixpanelContext";

const { BigNumber, utils, constants } = ethers;
const { AddressZero } = constants;

window.ethers = ethers;

const ChainContext = createContext();

export default ChainContext;

const desiredChainId = process.env.REACT_APP_CHAIN_ID;

export const ChainProvider = (props) => {
  const { children } = props;
  const [address, updateAddress] = useState("");
  const [injectedProvider, updateInjectedProvider] = useState(null);
  const [injectedNetwork, updateInjectedNetwork] = useState({
    name: "",
    chainId: "",
  });
  const [lastTx, updateLastTx] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const { mp } = useContext(MixpanelContext);

  useEffect(() => {
    if (
      mp &&
      address &&
      isAddress(address) &&
      address !== AddressZero &&
      injectedNetwork?.chainId
    ) {
      mp.register({
        wallet_address: address,
        chainId: injectedNetwork?.chainId
      });
    }
  }, [mp, address, injectedNetwork]);

  const connect = async () => {
    const providerOptions = {
      /* See Provider Options Section */
    };

    const web3Modal = new Web3Modal({
      cacheProvider: false,
      providerOptions, // required
    });

    try {
      let injectedProvider;
      if (process.env.REACT_APP_DIRECT_LOCAL_CHAIN == "true") {
        console.log("*** using local hardhat DIRECTLY ***");
        injectedProvider = ethers.providers.getDefaultProvider(
          "http://localhost:8545"
        );
      } else if (window?.chrome?.runtime?.id) {
        const instance = createMetaMaskProvider();
        await instance.request({ method: "eth_requestAccounts" });
        injectedProvider = new ethers.providers.Web3Provider(instance);
      } else {
        const instance = await web3Modal.connect();
        injectedProvider = new ethers.providers.Web3Provider(instance);
      }
      updateInjectedProvider(injectedProvider);
      const addresses = await injectedProvider.listAccounts();
      let walletIndex = 0;
      if (searchParams && searchParams.get("wi") != undefined) {
        console.log("going to use walletIndex: ", searchParams.get("wi"));
        walletIndex = searchParams.get("wi");
      }
      const address = addresses[walletIndex];
      if (address && isAddress(address)) {
        updateAddress(address);
      }

      const injectedNetwork = await injectedProvider.getNetwork();
      updateInjectedNetwork(injectedNetwork);
      mp.track("wallet connect");
    } catch (e) {
      console.error("error from metamask while connecting", e);
      disconnect();
      mp.track("wallet connect failure");
    }
  };
  const disconnect = async () => {
    updateAddress("");
    updateInjectedProvider(null);
    updateInjectedNetwork({ name: "", chainId: "" });
    mp.track("wallet disconnect");
  };
  useEffect(() => {
    const handleAccountChanged = ([account]) => {
      if (account) {
        connect();
      } else {
        disconnect();
      }
    };
    if (injectedProvider?.provider?.on) {
      injectedProvider.provider.on("disconnect", disconnect);
      injectedProvider.provider.on("accountsChanged", handleAccountChanged);
      injectedProvider.provider.on("chainChanged", connect);
      return () => {
        injectedProvider.provider.removeListener("disconnect", disconnect);
        injectedProvider.provider.removeListener(
          "accountsChanged",
          handleAccountChanged
        );
        injectedProvider.provider.removeListener("chainChanged", connect);
      };
    }
  }, [injectedProvider]);

  const switchNetwork = async () => {
    if (injectedProvider && injectedNetwork.chainId !== desiredChainId) {
      try {
        await injectedProvider.provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: toHex(desiredChainId) }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          console.log(networkParams);
          await injectedProvider.provider.request({
            method: "wallet_addEthereumChain",
            params: [networkParams[toHex(desiredChainId)]],
          });
        }
      }
    }
  };

  const obj = {
    address,
    injectedProvider,
    injectedNetwork,
    connect,
    disconnect,
    switchNetwork,
    lastTx,
    updateLastTx,
  };
  return <ChainContext.Provider value={obj}>{children}</ChainContext.Provider>;
};
