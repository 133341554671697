import {
  Alert,
  AlertIcon,
  Button,
  Container,
  Text,
  VStack,
} from '@chakra-ui/react'
import { ChainProvider } from '../contexts/ChainContext'
import { TopMenu } from '../components'
import { Outlet } from 'react-router-dom'
import { useEffect } from 'react'

export default function Home() {

  return (
      <ChainProvider>
        <Container py={0} maxWidth={"container.2xl"}>
          <VStack h={"100vh"}>
            <TopMenu />
            <Outlet />
          </VStack>
        </Container>
      </ChainProvider>
  );
}