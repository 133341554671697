import {
  Box,
  Fade,
  Heading,
  HStack,
  IconButton,
  Img,
  Spacer,
} from "@chakra-ui/react";
import { Bayc1, Bayc2, Bayc3 } from "../images";
import { CarouselCard, PromoCarouselCard } from "./index";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { MdPhone } from "react-icons/md";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { cards, promo } from "../data";
import { useContext, useEffect, useState } from "react";
import { useAllItems, useCardIndex, useContract } from "../hooks";
import MixpanelContext from '../contexts/MixpanelContext'

const CarouselNavButton = (props) => {
  const { children, handler } = props;
  return (
    <IconButton
      icon={children}
      variant={"solid"}
      colorScheme={"brand"}
      borderRadius="full"
      size={"lg"}
      onClick={handler}
    />
  );
};

export default function Carousel(props) {
  const { activeItems: cards, activeItemsCount: cardsCount } = useAllItems();
  const { index, decreaseIndex, increaseIndex } = useCardIndex();
  const firstIndex = (index + 0) % cardsCount;
  const secondIndex = (index + 1) % cardsCount;
  const { mp } = useContext(MixpanelContext);

  useEffect(() => {
    if (mp) {
      mp.track("visited carousel");
    }
  }, [mp]);

  if (cardsCount < 2) {
    return (
      <HStack h={"full"} w={"full"} bg={""} justify={"center"}>
        <PromoCarouselCard {...promo} />
      </HStack>
    );
  } else {
    return (
      <HStack h={"full"} w={"full"} bg={""} justify={"center"}>
        <Spacer />
        <CarouselNavButton handler={decreaseIndex}>
          <ArrowLeftIcon />
        </CarouselNavButton>
        <Spacer />

        <PromoCarouselCard {...promo} />
        <Spacer />

        <CarouselCard {...cards[firstIndex]} />
        <Spacer />
        <CarouselCard {...cards[secondIndex]} />

        <Spacer />
        <CarouselNavButton handler={increaseIndex}>
          <ArrowRightIcon />
        </CarouselNavButton>
        <Spacer />
      </HStack>
    );
  }
}
