import { useContext, useState } from 'react'
import MixpanelContext from '../contexts/MixpanelContext'

export default function useCardIndex(){
  const [index, updateIndex] = useState(0);
  const {mp} = useContext(MixpanelContext);

  const decreaseIndex = () => {
    updateIndex((prevState) => {
      mp.track("carousel navigated", {action: "decrease", currentIndex: index})
      if(prevState == 0){
        return 0
      }else{
        return prevState - 1;
      }
    });
  };
  const increaseIndex = () => {
    mp.track("carousel navigated", {action: "increase", currentIndex: index})
    updateIndex((prevState) => {
      return prevState + 1;
    });
  }
  return {index, decreaseIndex, increaseIndex}
}
