import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  CloseButton,
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Progress,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link as RRLink } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {
  Explanation1,
  Explanation2,
  Explanation3,
  Explanation4,
  HowItWorks1,
  HowItWorks2,
  HowItWorks3,
  HowItWorks4,
  Logo128,
} from "../images";
import MixpanelContext from "../contexts/MixpanelContext";

export default function HowItWorks(props) {
  const { mp } = useContext(MixpanelContext);
  const handleClose = (evt) => {
    mp.track("exited how it works");
  };
  const changeHandler = (idx) => {
    updateIdx(idx);
    mp.track("step change in how it works", {num: idx});
  };
  const nextHandler = (val) => {
    updateIdx(val);
    mp.track("next in how it works", {num: val});
  };
  const finishHandler = (val) => {
    updateIdx(val);
    mp.track("finished in how it works", {num: val});
  };
  const NumOfSteps = 4;
  const [idx, updateIdx] = useState(0);
  const progressValue = Math.ceil((100 / 4) * (idx + 1));
  const imageToShow = {
    0: Explanation1,
    1: Explanation2,
    2: Explanation3,
    3: Explanation4,
  };
  const MyAccordionButton = (props) => {
    const { children } = props;
    return (
      <AccordionButton textAlign={"left"} fontWeight={"bold"}>
        {children}
      </AccordionButton>
    );
  };
  const MyAccordionPanel = (props) => {
    const { children } = props;
    return (
      <AccordionPanel color={"gray.600"} fontWeight={"regular"}>
        {children}
      </AccordionPanel>
    );
  };
  const MyNextButton = (props) => {
    const { children, handler } = props;
    return (
      <Button onClick={handler} mt={4} colorScheme={"brand"}>
        {children}
      </Button>
    );
  };
  useEffect(() => {
    if (mp) {
      mp.track("visited HowItWorks");
    }
  }, [mp]);
  return (
    <Container py={0} maxWidth={"container.2xl"}>
      <Flex h={70} p={5} align={"center"}>
        <RRLink to={"/"}>
          {/*<Heading size={"lg"}>AlphaBack Logo</Heading>*/}
          {/*<Image src={Logo128} boxSize={'28px'} />*/}
        </RRLink>
        <Spacer />
        <RRLink to={"/"}>
          <CloseButton size={"md"} color={"gray.500"} onClick={handleClose} />
        </RRLink>
      </Flex>
      <Center h={"calc(100vh - 70px)"}>
        <VStack spacing={10}>
          <Flex direction={"row"} w={"full"}>
            <Accordion
              onChange={changeHandler}
              defaultIndex={0}
              index={idx}
              w={375}
            >
              <AccordionItem borderTop={0} borderBottom={0}>
                <MyAccordionButton>
                  1. Use your browser like normal
                </MyAccordionButton>
                <MyAccordionPanel>
                  <Text>
                    AlphaBack replaces your ‘new tab’ experience, showing you an
                    NFT gallery instead of a search bar. Just continue browsing
                    as you were and passively earn USDC!
                  </Text>
                  <MyNextButton handler={nextHandler.bind(this, 1)}>
                    Next Step
                  </MyNextButton>
                </MyAccordionPanel>
              </AccordionItem>
              <AccordionItem borderTop={0} borderBottom={0}>
                <MyAccordionButton>
                  2. Creators want to show your their NFTs
                </MyAccordionButton>
                <MyAccordionPanel>
                  <Text>
                    NFT creators want to share their projects with the world, so
                    they compete for upvotes to earn the #1 spot in your new
                    tab. Others pay to promote their NFTs in the new tab.
                  </Text>
                  <MyNextButton handler={nextHandler.bind(this, 2)}>
                    Next Step
                  </MyNextButton>
                </MyAccordionPanel>
              </AccordionItem>
              <AccordionItem borderTop={0} borderBottom={0}>
                <MyAccordionButton>
                  3. Get a share of advertiser payments
                </MyAccordionButton>
                <MyAccordionPanel>
                  <Text>
                    You are eligible to get a share of ad payments by being an
                    daily active user of the browser extension. Payments split
                    proportionally among users via smart contract.
                  </Text>
                  <MyNextButton handler={nextHandler.bind(this, 3)}>
                    Next Step
                  </MyNextButton>
                </MyAccordionPanel>
              </AccordionItem>
              <AccordionItem borderTop={0} borderBottom={0}>
                <MyAccordionButton>4. Withdraw your payments</MyAccordionButton>
                <MyAccordionPanel>
                  <Text>
                    Withdraw your share of ad payments in the settings page,
                    prompting a wallet transaction.
                  </Text>
                  <RRLink to={"/"}>
                    <MyNextButton>Finish Demo</MyNextButton>
                  </RRLink>
                </MyAccordionPanel>
              </AccordionItem>
            </Accordion>
            <Box w={"10"}></Box>
            {/*<Box w={"full"} w={750} boxShadow={"dark-lg"}>*/}
            <Box w={"full"} w={750}>
              <Image src={imageToShow[idx]} objectFit={"cover"} w={750} />
            </Box>
          </Flex>
          <Box w={"75%"}>
            <Progress
              value={progressValue}
              size={"sm"}
              colorScheme={"brand"}
              hasStripe
            />
          </Box>
        </VStack>
      </Center>
    </Container>
  );
}
