import { useContract } from "./index";
import { useContext, useEffect, useState } from "react";
import ChainContext from "../contexts/ChainContext";
import moment from "moment";

export default function useAllItems() {
  const { injectedProvider, lastTx } = useContext(ChainContext);
  const { reader } = useContract("LeaderBoard");
  const [allItems, updateAllItems] = useState([]);
  const today = moment.utc().unix();
  useEffect(() => {
    const fetch = async () => {
      if (reader) {
        let allItems = await reader.getAllItems();
        updateAllItems(allItems);
      }
    };
    fetch();
  }, [reader, lastTx]);

  const activeItems = allItems.filter(
    (item) => item.startTime.lte(today) && item.endTime.gte(today)
  );
  activeItems.sort((a, b) => b.upVotes - a.upVotes);
  return {
    allItems,
    activeItems,
    activeItemsCount: activeItems.length,
    allItemsCount: allItems.length,
  };
}
