import { useContext, useEffect, useRef, useState } from 'react'
import ChainContext from "../contexts/ChainContext";
import useTransactionWrapper from './useTransactionWrapper'

export default function useExecuteOrConnect(fnToExecute, writer) {
  const { address, connect } = useContext(ChainContext);
  const [wantsToExecute, updateWantsToExecute] = useState(false);
  const upVoteToastIdRef = useRef();
  const wrappedFn = useTransactionWrapper();

  useEffect(() => {
    const init = async () => {
      if (wantsToExecute && writer && address) {
        updateWantsToExecute(false);
        await wrappedFn(async () => {
          return await fnToExecute()
        }, upVoteToastIdRef);
      } else if (wantsToExecute && !address && connect) {
        await connect();
      }
    };
    init();
  }, [connect, writer, address, wantsToExecute]);

  return () => updateWantsToExecute(true);
}
