import { createContext, useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";

const MixpanelContext = createContext();
export default MixpanelContext;

const mixpanelBatch = process.env.NODE_ENV == "production" ? true : false;
const mixpanelDebug = process.env.NODE_ENV == "production" ? false : true;

export const MixpanelProvider = (props) => {
  const { children } = props;
  const [mp, updateMp] = useState(null);

  useEffect(() => {
    if (mixpanel && mixpanel.init) {
      mixpanel.init(process.env.REACT_APP_MIXPANEL, {
        debug: mixpanelDebug,
        ignore_dnt: true,
        batch_requests: mixpanelBatch,
      });
      updateMp(mixpanel);
    }
  }, [mixpanel]);

  const obj = {
    mp,
  };
  return (
    <MixpanelContext.Provider value={obj}>{children}</MixpanelContext.Provider>
  );
};
