import moment from "moment";
import axios from "axios";
import { AdPlaceholder, Bayc1 } from '../images'

const ALCHEMY_KEY = process.env.REACT_APP_ALCHEMY_API_KEY;

const getFromLocalStorage = (key) => {
  try {
    let value = window.localStorage.getItem(key);
    if (value) {
      value = JSON.parse(value);
      if (value.expiration > moment().unix()) {
        return value.data;
      } else {
        window.localStorage.removeItem(key);
      }
      return "";
    }
  } catch (e) {
    return "";
  }
};

const getImageObjFromNftToken = async (
  nftContractAddress,
  nftTokenId,
  networkName
) => {
  const key = `alchemy-nftImageUrl-${nftContractAddress}-${nftTokenId}`;
  const value = getFromLocalStorage(key);
  if (value) {
    return value;
  }

  let url;
  if (networkName == "polygon") {
    url = `https://polygon-mainnet.g.alchemy.com/nft/v2/${ALCHEMY_KEY}/getNFTMetadata?contractAddress=${nftContractAddress}&tokenId=${nftTokenId}`;
  } else if (networkName == "mainnet") {
    url = `https://eth-mainnet.alchemyapi.io/nft/v2/${ALCHEMY_KEY}/getNFTMetadata?contractAddress=${nftContractAddress}&tokenId=${nftTokenId}`;
  } else {
    return "";
  }

  try {
    const result = await axios({
      method: "get",
      url: url,
    });
    const value = result.data;
    window.localStorage.setItem(
      key,
      JSON.stringify({
        expiration: moment().add(60, "minutes").unix(),
        data: value,
      })
    );
    return value;
  } catch (e) {
    console.error("error getting nft data: ", e);
    return "";
  }
};

const getImageUrlFromNftToken = async (
  nftContractAddress,
  nftTokenId,
  networkName,
  imageUrl
) => {
  if (imageUrl && imageUrl.length > 5) {
    return imageUrl;
  }

  const imageObj = await getImageObjFromNftToken(
    nftContractAddress,
    nftTokenId,
    networkName
  );
  if (imageObj && imageObj.media && imageObj.media[0]) {
    if (imageObj.media[0].thumbnail) {
      imageUrl = imageObj.media[0].thumbnail;
    } else if (imageObj.media[0].gateway) {
      imageUrl = imageObj.media[0].gateway;
    } else if (imageObj.media[0].raw) {
      imageUrl = imageObj.media[0].raw;
    }
  }
  return imageUrl;
};

export default getImageUrlFromNftToken;
