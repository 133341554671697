import {
  Button,
  Center,
  Collapse,
  Fade,
  Heading,
  Highlight,
  Image,
  Link,
  LinkOverlay,
  ScaleFade,
  Skeleton,
  SlideFade,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ExternalLinkIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { useContext, useEffect } from "react";
import ChainContext from "../contexts/ChainContext";
import { usePromotion } from "../hooks";
import { ethers } from "ethers";
import { isAddress } from "ethers/lib/utils";
import MixpanelContext from "../contexts/MixpanelContext";

const { BigNumber, utils, constants } = ethers;
const { AddressZero } = constants;

export default function PromoCarouselCard(props) {
  const { connect } = useContext(ChainContext);
  const promoObj = usePromotion();
  const { title, description, imageUrl, clickUrl, promoter, amount } = promoObj;
  const promotionContractUrl = "https://app.alphaback.xyz/promotions";
  const { mp } = useContext(MixpanelContext);

  useEffect(() => {
    if (mp && promoter && isAddress(promoter) && promoter !== AddressZero) {
      mp.track("Promotion Shown: ", promoObj);
    }
  }, [mp, promoter]);

  const BottomDisclaimerText = () => {
    if (promoter === AddressZero) {
      return "";
    } else if (promoter && amount && amount.gt(0)) {
      return (
        <Text fontSize={"0.625rem"}>
          *This is a promoted ad. It's revenue is being distributed pro-rata
          among the install base.
        </Text>
      );
    } else if (promoter && amount && amount.eq(0)) {
      return (
        <Text fontSize={"0.625rem"}>
          *This is a placeholder image. No revenue is being generated from it.
        </Text>
      );
    }
  };

  const BrandAdUnit = () => {
    return (
      <Center
        w={250}
        h={250}
        bg={"brand.100"}
        color={"black"}
        fontWeight={"semibold"}
        fontSize={"lg"}
      >
        Your Ad Here
      </Center>
    );
  };

  return (
    <>
      <VStack
        spacing={2}
        w={250}
        align={"start"}
        boxShadow={"2xl"}
        rounded={"md"}
        sx={{ transition: "all .4s ease-in-out" }}
        _hover={{ boxShadow: "dark-lg", transform: "scale(1.1)" }}
      >
        <Image
          objectFit="cover"
          w={"full"}
          src={imageUrl}
          // fallback={<Skeleton w={250} h={250} speed={5}></Skeleton>}
          fallback={<BrandAdUnit />}
        />

        <VStack spacing={4} p={6} align={"start"}>
          <Heading as="h2" size="md" noOfLines={2}>
            {title}
          </Heading>
          <Text noOfLines={8}>{description}</Text>
          <Button
            as={"a"}
            href={promoter ? clickUrl : promotionContractUrl}
            target={"_blank"}
            leftIcon={<ExternalLinkIcon />}
            colorScheme={"brand"}
            onClick={() => {
              mp.track("Promo Clicked", promoObj);
            }}
          >
            {promoter && "Learn More"}
            {!promoter && "Promote"}
          </Button>
          {<BottomDisclaimerText />}
        </VStack>
      </VStack>
    </>
  );
}
