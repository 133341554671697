import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, HashRouter, MemoryRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import "./index.css";
import { MixpanelProvider } from "./contexts/MixpanelContext";
import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
  colors: {
    brand: {
      100: "#feecc7",
      200: "#fcd27c",
      300: "#f5a504",
      400: "#dc9404",
      500: "#dc9404",
      600: "#916103",
      700: "#785002",
      800: "#5f4002",
      900: "#462f01",
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HashRouter>
      <ChakraProvider theme={theme}>
        <MixpanelProvider>
          <App />
        </MixpanelProvider>
      </ChakraProvider>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
