import {
  Button,
  ChakraProvider,
  Container,
  Heading,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { TopMenu, Carousel, HowItWorks } from "./components";
import { ChainProvider } from "./contexts/ChainContext";
import Uninstall from "./views/Uninstall";
import { Route, Routes } from "react-router-dom";
import { SubmitItem, EditItem, ViewAllItems } from './views'
import NotFound from './components/NotFound'
const { Home } = require("./views");

function App() {
  return (
    <Routes>
      <Route path="/uninstall" element={<Uninstall />} />
      <Route path="/how-it-works" element={<HowItWorks />} />

      <Route path={"/*"} element={<Home />}>
        <Route index element={<Carousel />} />
        <Route path={'carousel'} element={<Carousel />} />
        <Route path={'view-all-items'} element={<ViewAllItems />} />
        <Route path={'submit-item'} element={<SubmitItem />} />
        <Route path={'edit-item/:editingId'} element={<EditItem />} />
        <Route path="*" element={<Carousel />} />
      </Route>
    </Routes>
  );
}

export default App;
