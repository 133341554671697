import {
  Box,
  Flex,
  Heading,
  Spacer,
} from "@chakra-ui/react";
import { CarouselCard, ItemForm } from "../components";
import { useEffect } from "react";
import moment from "moment";
import useContract from "../hooks/useContract";
import { ethers } from "ethers";
import { useExecuteOrConnect, useItemObj } from '../hooks'
import { useParams } from "react-router-dom";

const { BigNumber, utils, constants } = ethers;
const { AddressZero } = constants;

export default function EditItem(props) {
  let { editingId } = useParams();
  const [itemObj, dispatch] = useItemObj();
  const {
    title,
    description,
    imageUrl,
    upVotes,
    clickUrl,
    startDate,
    endDate,
    id,
  } = itemObj;
  const { reader, writer } = useContract("LeaderBoard");
  useEffect(() => {
    const fetchItem = async () => {
      if (reader && editingId) {
        const item = await reader.itemsById(editingId);
        const obj = {
          title: item.title,
          description: item.description,
          imageUrl: item.imageUrl,
          upVotes: item.upVotes,
          id: item.id,
          clickUrl: item.clickUrl,
          startDate: moment.unix(item.startTime),
          endDate: moment.unix(item.endTime),
        };
        dispatch({ type: "replace", obj });
      }
    };
    fetchItem();
  }, [reader, editingId]);
  const updateWantsToEdit = useExecuteOrConnect(async () => {
    const obj = {
      submitter: AddressZero,
      imageUrl,
      clickUrl,
      title,
      description,
      startTime: startDate.unix(),
      endTime: endDate.unix(),
      upVotes: BigNumber.from(upVotes),
      id: BigNumber.from(id),
    };
    return await writer.updateItem(editingId, obj);
  }, writer);
  return (
    <Box w={"full"}>
      <Heading size={"lg"} textAlign={"center"} mb={10}>
        Edit Item ID {editingId}
      </Heading>
      <Flex direction={"row"}>
        <Spacer />
        <ItemForm
          dispatch={dispatch}
          itemObj={itemObj}
          submitHandler={updateWantsToEdit}
        />
        <Spacer />
        <CarouselCard {...itemObj} />
        <Spacer />
      </Flex>
    </Box>
  );
}
