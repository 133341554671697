export function toHex(num) {
  const val = Number(num);
  return "0x" + val.toString(16);
}
export function buildDisplayAddress(address) {
  let displayAddress;
  if (address) {
    displayAddress = address.slice(0, 5) + "..." + address.slice(-4);
  } else {
    displayAddress = address;
  }
  return displayAddress;
}
