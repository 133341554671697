import { Box, Heading, Input } from "@chakra-ui/react";
import { useState } from "react";

import { create, urlSource } from "ipfs-http-client";
window.create = create;
window.urlSource = urlSource;

const projectId = "2EUWm34YYRz6LId6SqJk9KcLtqR";
const projectSecret = "9be969f51b0456c29342738cb753018a";
const auth =
  "Basic " + Buffer.from(projectId + ":" + projectSecret).toString("base64");

const ipfs = create({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  headers: {
    authorization: auth,
  },
});
window.ipfs = ipfs;

export default function ImageUploader(props) {


  const [fileUrl, updateFileUrl] = useState("");

  const fileHandler = async (evt) => {
    console.log("got file: ", evt.target.files[0]);
    const file = evt.target.files[0];
    try {
      const obj = await ipfs.add(file);
      console.log("got back: ", obj);
      const url = `https://ipfs.io/ipfs/${obj.path}`;
      updateFileUrl(url);
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  };
  return (
    <>
      <Heading>Image Uploader</Heading>
      <Input
        placeholder={"add image"}
        type={"file"}
        onChange={fileHandler}
      ></Input>
      <Box>File Url: {fileUrl}</Box>
    </>
  );
}
