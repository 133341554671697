import {
  Alert,
  AlertIcon,
  Badge,
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import {
  BellIcon,
  ExternalLinkIcon,
  InfoIcon,
  SettingsIcon,
  StarIcon,
  WarningTwoIcon,
} from "@chakra-ui/icons";
import { RiWallet3Fill } from "react-icons/ri";
import { AiFillQuestionCircle, AiOutlineTeam, AiOutlineDollarCircle } from "react-icons/ai";
import { useContext, useEffect, useRef, useState } from "react";
import { formatUnits, isAddress } from "ethers/lib/utils";
import ChainContext from "../contexts/ChainContext";
import useContract from "../hooks/useContract";
import { useMemberBalance } from "../hooks";
import { chainNames, getSeqDates } from "../helpers";
import { buildDisplayAddress } from "../helpers/utils";
import { Link as RRLink, useLocation } from "react-router-dom";
import useTransactionWrapper from "../hooks/useTransactionWrapper";
import { FaList } from "react-icons/fa";
import { Logo128 } from "../images";
import MixpanelContext from "../contexts/MixpanelContext";

export default function TopMenu(props) {
  const {
    address,
    injectedNetwork,
    injectedProvider,
    connect,
    disconnect,
    switchNetwork,
  } = useContext(ChainContext);

  const displayAddress = buildDisplayAddress(address);
  const { reader, writer } = useContract("Promotions");
  const balance = useMemberBalance(reader, address);
  const withdrawalToastIdRef = useRef();
  const wrappedFn = useTransactionWrapper();
  const { mp } = useContext(MixpanelContext);
  const location = useLocation();

  const withdrawBalance = async () => {
    const seqDates = await getSeqDates(reader, address); // read only
    await wrappedFn(async () => {
      return await writer.memberWithdrawBalance(seqDates);
    }, withdrawalToastIdRef);
    mp.track("withdrawal");
  };

  const shouldShowLogo = ["view-all-items", "submit-item", "edit-item"].some(
    (str) => location?.pathname?.indexOf(str) >= 0
  );

  return (
    <HStack w={"full"} h={70} p={5}>
      <Box>
        <RRLink to={"/"}>
          {/*<Heading size={"lg"}>AlphaBack Logo</Heading>*/}
          {/*<Image src={Logo128} boxSize={'28px'} />*/}
          {shouldShowLogo && <Image src={Logo128} boxSize={"28px"} />}
        </RRLink>
      </Box>
      <Spacer />
      {injectedNetwork.chainId &&
        injectedNetwork.chainId != process.env.REACT_APP_CHAIN_ID && (
          <Tooltip
            label={`Switch to ${chainNames[process.env.REACT_APP_CHAIN_ID]}`}
            hasArrow
          >
            <Button
              colorScheme={"red"}
              onClick={switchNetwork}
              leftIcon={<WarningTwoIcon />}
              size={"md"}
            >
              Wrong Network
            </Button>
          </Tooltip>
        )}
      {!address && (
        <Button colorScheme={"brand"} onClick={connect} size={"md"}>
          Connect Wallet
        </Button>
      )}
      {address && (
        <Tooltip
          label={
            <Box>
              Connected as <br />
              {address}
              <br /> to {injectedNetwork?.name}
            </Box>
          }
          hasArrow
          maxW={"500px"}
          placement={"bottom-start"}
        >
          <Badge
            colorScheme="brand"
            p={2}
            borderRadius={"md"}
            fontSize={"0.95em"}
          >
            {displayAddress}
          </Badge>
        </Tooltip>
      )}
      {address && (
        <Tooltip
          label="Your share of advertising revenue generated by the extension"
          placement={"bottom-start"}
          hasArrow
        >
          <Badge
            p={2}
            borderRadius={"md"}
            colorScheme="blackAlpha"
            fontSize={"0.95em"}
          >
            $ {formatUnits(balance, 6)}
          </Badge>
        </Tooltip>
      )}
      <Box>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<SettingsIcon />}
            variant="outline"
            colorScheme={"brand"}
            size={"md"}
          />
          <MenuList>
            {!address && (
              <MenuItem icon={<Icon as={RiWallet3Fill} />} onClick={connect}>
                Connect Wallet
              </MenuItem>
            )}
            {address && (
              <MenuItem icon={<Icon as={RiWallet3Fill} />} onClick={disconnect}>
                Disconnect Wallet
              </MenuItem>
            )}

            {address && (
              <MenuItem
                icon={<Icon as={AiOutlineDollarCircle} />}
                onClick={withdrawBalance}
              >
                Withdraw Balance
              </MenuItem>
            )}

            <RRLink to="how-it-works">
              <MenuItem icon={<InfoIcon />}>How It Works</MenuItem>
            </RRLink>

            {/*<MenuItem icon={<BsFillGearFill />}>Settings</MenuItem>*/}

            {/*<RRLink to="uninstall">*/}
            {/*  <MenuItem icon={<BellIcon />}>Post Uninstall Screen</MenuItem>*/}
            {/*</RRLink>*/}

            <RRLink to="submit-item">
              <MenuItem icon={<StarIcon />}>Submit Item</MenuItem>
            </RRLink>

            <RRLink to="view-all-items">
              <MenuItem icon={<FaList />}>All Items</MenuItem>
            </RRLink>
            
            <Link href={"https://alphaback.xyz/faq"} isExternal>
              <MenuItem icon={<AiFillQuestionCircle />}>
                FAQ <ExternalLinkIcon mx="2px" mb={"4px"} />
              </MenuItem>
            </Link>

            <Link href={"https://discord.gg/uKQgkteu72"} isExternal>
              <MenuItem icon={<AiOutlineTeam />}>
                Support <ExternalLinkIcon mx="2px" mb={"4px"} />
              </MenuItem>
            </Link>
          </MenuList>
        </Menu>
      </Box>
    </HStack>
  );
}
