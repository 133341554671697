import useTransactionToasts from "./useTransactionToasts";
import { Box, Link, useToast } from "@chakra-ui/react";
import { buildDisplayAddress } from "../helpers/utils";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { useContext, useState } from 'react'
import ChainContext from '../contexts/ChainContext'

const MessageWithHash = (props) => {
  const { hash } = props;
  return (
    <Box>
      Tx Hash:{" "}
      <Link
        href={`https://polygonscan.com/tx/${hash}`}
        isExternal
        textDecoration="underline"
      >
        View on Polyscan - {buildDisplayAddress(hash)}{" "}
        <ExternalLinkIcon mx="2px" mb={"4px"} />
      </Link>
    </Box>
  );
};

const pleaseConfirmMessage = () => ({
  title: "Transaction Initiated.",
  description: "Please confirm the transaction in your wallet.",
  status: "info",
  duration: null,
  isClosable: true,
});

const hasStartedMessage = (hash) => ({
  title: "Transaction Started.",
  status: "info",
  description: <MessageWithHash hash={hash} />,
  duration: null,
  isClosable: true,
});

const isConfirmedMessage = (hash) => ({
  title: "Transaction Confirmed.",
  status: "success",
  description: <MessageWithHash hash={hash} />,
  duration: 5000,
  isClosable: true,
});

const hasFailedMessage = (e) => ({
  title: "Transaction Failed.",
  description: e.code && e.message ? `${e.code}: ${e.reason}` : e.message,
  status: "error",
  duration: 5000,
  isClosable: true,
});

export default function useTransactionWrapper() {
  const toast = useToast();
  const { updateLastTx, address } = useContext(ChainContext);

  const wrappedFn = async (fn, toastIdRef) => {
    try {
      toastIdRef.current = toast(pleaseConfirmMessage());
      const result = await fn();
      toast.update(toastIdRef.current, hasStartedMessage(result.hash));
      if (result) {
        const receipt = await result.wait();
        if (!receipt || (receipt && receipt.status == 1)) {
          toast.update(
            toastIdRef.current,
            isConfirmedMessage(result.transactionHash)
          );
          updateLastTx(receipt.transactionHash);
        } else {
          toast.update(
            toastIdRef.current,
            hasFailedMessage({ message: "tx mining failed" })
          );
        }
      } else {
        toast.update(toastIdRef.current, hasFailedMessage({ message: "tx failed" }));
      }
    } catch (e) {
      console.log("transaction failed: ", e);
      toast.update(toastIdRef.current, hasFailedMessage(e));
    }
  };

  return wrappedFn;
}
