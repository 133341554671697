import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Image,
  Skeleton,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useAllItems } from "../hooks";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import moment from "moment";
import { buildDisplayAddress } from "../helpers/utils";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  EditIcon,
  StarIcon,
} from '@chakra-ui/icons'
import { Link as RRLink } from "react-router-dom";

export default function ViewAllItems() {
  const { allItems } = useAllItems();

  return (
    <VStack w={"full"} spacing={10} align={"start"}>
      <Flex direction={'row'} w={"full"}>
        {/*<Box>*/}
        {/*  <RRLink to={"/"}>*/}
        {/*    <Button leftIcon={<ArrowLeftIcon />}>Go Back</Button>*/}
        {/*  </RRLink>*/}
        {/*</Box>*/}
        <Heading size={"lg"} textAlign={"center"} flexGrow={1}>
          All Items
        </Heading>
        <Box>
          <RRLink to={"/submit-item"}>
            <Button leftIcon={<StarIcon />}>Submit New Item</Button>
          </RRLink>
        </Box>
      </Flex>

      <TableContainer w={"full"}>
        <Table variant="simple" border='1px' borderColor='brand.700' colorScheme={'brand'}>
          <TableCaption>All Items ever submitted to the contract</TableCaption>
          <Thead>
            <Tr>
              <Th>Id</Th>
              <Th>Submitter</Th>
              <Th>Image</Th>
              <Th>Title</Th>
              <Th>Description</Th>
              <Th>UpVotes</Th>
              <Th>Start</Th>
              <Th>End</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {allItems.map(
              ({
                submitter,
                title,
                description,
                imageUrl,
                upVotes,
                clickUrl,
                startTime,
                endTime,
                id,
              }) => (
                <Tr>
                  <Td>{id?.toString()}</Td>
                  <Td>{buildDisplayAddress(submitter)}</Td>
                  <Td>
                    <Image
                      w={20}
                      src={imageUrl}
                      alt={imageUrl}
                      fallback={
                        <Skeleton
                          h={10}
                          startColor={"gray.300"}
                          endColor={"gray.300"}
                        />
                      }
                    />
                    {}
                  </Td>
                  <Td>
                    <Tooltip label={title}>
                      <Text maxW={100} noOfLines={1}>
                        {title}
                      </Text>
                    </Tooltip>
                  </Td>
                  <Td>
                    <Tooltip label={description}>
                      <Text maxW={150} noOfLines={1}>
                        {description}
                      </Text>
                    </Tooltip>
                  </Td>
                  <Td>{upVotes?.toString()}</Td>
                  <Td>
                    {startTime && moment.unix(startTime).format("YYYY-MM-DD")}
                  </Td>
                  <Td>
                    {endTime && moment.unix(endTime).format("YYYY-MM-DD")}
                  </Td>
                  <Td>
                    <RRLink to={`/edit-item/${id}`}>
                      <IconButton icon={<EditIcon />} />
                    </RRLink>
                  </Td>
                </Tr>
              )
            )}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th>Id</Th>
              <Th>Submitter</Th>
              <Th>Image</Th>
              <Th>Title</Th>
              <Th>Description</Th>
              <Th>UpVotes</Th>
              <Th>Start</Th>
              <Th>End</Th>
              <Th>Actions</Th>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
    </VStack>
  );
}
