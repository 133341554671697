import { useContract } from "./index";
import { useEffect, useState } from "react";
import moment from "moment";
import { getImageUrlFromNftToken } from "../helpers";
import { ethers } from "ethers";
import { AdPlaceholder } from '../images'

const { BigNumber, utils, constants } = ethers;
const { AddressZero } = constants;

export default function usePromotion() {
  const { reader, writer } = useContract("Promotions");
  const [promoObj, setPromoObj] = useState({
    title: "Your Ad Title",
    description: "Promote your product here by paying the smart contract.",      
    imageUrl: null,
    upVotes: 0,
    clickUrl: "https://app.alphaback.xyz/promotions",
    id: 0,
    promoter: null,
    amount: BigNumber.from(0),
    nftContractAddress: null,
    nftTokenId: null,
    networkName: null,
  });
  const updatePromoImage = (key, value) => {
    setPromoObj((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  useEffect(() => {
    const fetchPromotion = async () => {
      if (reader) {
        const now = moment().utc().startOf("day").unix();
        const result = await reader.promotions(now);
        const {
          promoter,
          clickThruUrl,
          nftContractAddress,
          nftTokenId,
          networkName,
          imageUrl,
          title,
          subTitle,
          amount,
        } = result;
        if (result && result.promoter && result.promoter !== AddressZero) {
          let image = await getImageUrlFromNftToken(
            nftContractAddress,
            nftTokenId,
            networkName,
            imageUrl,
          );
          updatePromoImage("imageUrl", image);
          updatePromoImage("title", title);
          updatePromoImage("description", subTitle);
          updatePromoImage("clickUrl", clickThruUrl);
          updatePromoImage("promoter", promoter);
          updatePromoImage("amount", amount);
          updatePromoImage("nftContractAddress", nftContractAddress);
          updatePromoImage("nftTokenId", nftTokenId);
          updatePromoImage("networkName", networkName);
        }
      }
    };
    fetchPromotion();
  }, [reader]);
  return promoObj;
}
