import {
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
} from "@chakra-ui/react";
import moment from "moment/moment";
import { Bayc1 } from "../images";
import { useReducer } from "react";

export default function ItemForm(props) {
  const { itemObj, dispatch, submitHandler } = props;
  console.log("*** itemObj: ", itemObj);
  const {
    title,
    description,
    imageUrl,
    upVotes,
    clickUrl,
    startDate,
    endDate,
    id,
  } = itemObj;

  return (
    <VStack w={700} spacing={5} my={5} align={"left"}>
      <FormControl isRequired>
        <FormLabel>Title</FormLabel>
        <Input
          value={title}
          placeholder="Item Title goes here"
          onChange={(evt) =>
            dispatch({
              type: "update",
              key: "title",
              value: evt.target.value,
            })
          }
        />
      </FormControl>

      <FormControl isRequired>
        <FormLabel>Description</FormLabel>
        <Input
          value={description}
          placeholder="And Description goes here"
          onChange={(evt) =>
            dispatch({
              type: "update",
              key: "description",
              value: evt.target.value,
            })
          }
        />
      </FormControl>

      <FormControl isRequired>
        <FormLabel>Image Url</FormLabel>
        <Input
          value={imageUrl}
          placeholder="The image URL"
          onChange={(evt) =>
            dispatch({
              type: "update",
              key: "imageUrl",
              value: evt.target.value,
            })
          }
        />
      </FormControl>

      <FormControl isRequired>
        <FormLabel>Click Url</FormLabel>
        <Input
          value={clickUrl}
          placeholder="The Click-thru URL"
          onChange={(evt) =>
            dispatch({
              type: "update",
              key: "clickUrl",
              value: evt.target.value,
            })
          }
        />
      </FormControl>

      <FormControl isRequired>
        <FormLabel>Start Day</FormLabel>
        <Input
          placeholder=""
          type={"date"}
          value={startDate && startDate.format("YYYY-MM-DD")}
          onChange={(evt) =>
            dispatch({
              type: "update",
              key: "startDate",
              value: moment(evt.target.value).utc().startOf("day"),
            })
          }
        />
      </FormControl>

      <FormControl isRequired>
        <FormLabel>End Day</FormLabel>
        <Input
          placeholder=""
          type={"date"}
          value={endDate && endDate.format("YYYY-MM-DD")}
          onChange={(evt) =>
            dispatch({
              type: "update",
              key: "endDate",
              value: moment(evt.target.value).utc().startOf("day"),
            })
          }
        />
      </FormControl>

      <Button w={"fit-content"} colorScheme={"brand"} onClick={submitHandler}>
        Submit
      </Button>
    </VStack>
  );
}
