import { toHex } from "./utils";

const polygonChainId = 137;
const polygonHexChainId = toHex(polygonChainId);
const localChainId = 31337;
const localHexChainId = toHex(localChainId);

export const networkParams = {
  [polygonHexChainId]: {
    chainId: polygonHexChainId,
    rpcUrls: ["https://polygon-rpc.com"],
    chainName: "Polygon Mainnet",
    nativeCurrency: { name: "Matic", decimals: 18, symbol: "MATIC" },
    blockExplorerUrls: ["https://polygonscan.com"],
    iconUrls: [],
  },
  [localHexChainId]: {
    chainId: localHexChainId,
    rpcUrls: ["http://127.0.0.1:8545"],
    chainName: `Localhost - ${localChainId}`,
    nativeCurrency: { name: "LOCAL", decimals: 18, symbol: "LOCAL" },
  },
};
