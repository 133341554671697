import {
  Bayc1,
  Bayc2,
  Bayc3,
  Bayc4,
  Bayc5,
  Bayc6,
  Bayc7,
  Bayc8,
  Bayc9,
  Bayc10,
  AdPlaceholder,
} from "./images";
import { BigNumber } from 'ethers'

export const promo = {
  imageUrl: AdPlaceholder,
  title: "Your Ad Title",
  description: "Promote your product here by paying the smart contract.",
};

export const cards = [
  {
    imageUrl: Bayc1,
    title: "Magister",
    descripton: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium architecto deleniti doloribus labore numquam, qui sunt! Quam quibusdam quos sunt?",
    upVotes: BigNumber.from(110),
    clickUrl: "https://opensea.io/",
  },
  {
    imageUrl: Bayc2,
    title: "Nunquam",
    descripton: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure, pariatur.",
    upVotes: BigNumber.from(510),
    clickUrl: "https://opensea.io/",

  },
  {
    imageUrl: Bayc3,
    title: "Itineris",
    descripton: "A falsis, mons varius terror. Sunt parmaes transferre altus, rusticus triticumes.",
    upVotes: BigNumber.from(310),
    clickUrl: "https://opensea.io/",
  },
  {
    imageUrl: Bayc4,
    title: "Lipsum",
    descripton: "Ecce, lanista! Clemens contencio hic examinares mens est.A falsis, sectam fortis aonides.Domesticus frondator cito apertos vigil est.Vae, messor!",
    upVotes: BigNumber.from(150),
    clickUrl: "https://opensea.io/",

  },
  {
    imageUrl: Bayc5,
    title: "Lorem",
    descripton: "Habitio fortis cannabis est.Apolloniates, usus, et castor.Nunquam captis heuretes.Cur ionicis tormento ortum?",
    upVotes: BigNumber.from(234),
    clickUrl: "https://opensea.io/",
  },
  {
    imageUrl: Bayc6,
    title: "Hortus ",
    descripton: "A falsis, zeta primus castor. Germanus caesium etiam experientias zeta est?",
    upVotes: BigNumber.from(234),
    clickUrl: "https://opensea.io/",
  },
  {
    imageUrl: Bayc7,
    title: "Mons ",
    descripton: "Messors trabem! Camerarius, germanus liberis una examinare de noster, nobilis vortex?",
    upVotes: BigNumber.from(234),
    clickUrl: "https://opensea.io/",
  },
  {
    imageUrl: Bayc8,
    title: "domesticus brabeuta.",
    descripton: "Habitio fortis cannabis est.Apolloniates, usus, et castor.Nunquam captis heuretes.Cur ionicis tormento ortum?",
    upVotes: BigNumber.from(234),
    clickUrl: "https://opensea.io/",
  },
  {
    imageUrl: Bayc9,
    title: "cannabis",
    descripton: "est.Apolloniates, usus, et castor.Nunquam captis heuretes.Cur ionicis tormento ortum?",
    upVotes: BigNumber.from(400),
    clickUrl: "https://opensea.io/",
  },
  {
    imageUrl: Bayc10,
    title: "Habitio",
    descripton: "fortis cannabis est.Apolloniates, usus, et castor.Nunquam captis heuretes.Cur ionicis tormento ortum?",
    upVotes: BigNumber.from(214),
    clickUrl: "https://opensea.io/",
  },
];
