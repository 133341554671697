import { useContext, useEffect, useState } from "react";
import ChainContext from "../contexts/ChainContext";
import ProxyContracts from "../contracts/proxy_contracts.json";
import { ethers } from "ethers";
import { chainUrls } from "../helpers";

export default function useContract(contractName) {
  const {
    address,
    injectedProvider,
    injectedNetwork: { chainId },
  } = useContext(ChainContext);
  const [reader, updateReader] = useState(null);
  const [writer, updateWriter] = useState(null);

  const desiredChainId = process.env.REACT_APP_CHAIN_ID;

  useEffect(() => {
    const setupWriter = () => {
      if (
        injectedProvider &&
        address &&
        contractName &&
        ProxyContracts &&
        chainId &&
        ProxyContracts?.[chainId]?.[contractName]?.abi
      ) {
        const contractAbi = ProxyContracts?.[chainId]?.[contractName]?.abi;
        const contractAddress =
          ProxyContracts?.[chainId]?.[contractName]?.address;
        const signer = injectedProvider.getSigner(address);
        const contractWriter = new ethers.Contract(
          contractAddress,
          contractAbi,
          signer
        );
        updateWriter(contractWriter);
      }
    };
    setupWriter();
  }, [injectedProvider, address, contractName, ProxyContracts, chainId]);

  useEffect(() => {
    const setupReader = () => {
      if (chainUrls && desiredChainId && contractName && ProxyContracts) {
        const chainUrl = chainUrls[desiredChainId];
        const chainProvider = ethers.providers.getDefaultProvider(chainUrl);
        const contractAbi = ProxyContracts?.[desiredChainId]?.[contractName]?.abi;
        const contractAddress =
          ProxyContracts?.[desiredChainId]?.[contractName]?.address;
        const contractReader = new ethers.Contract(
          contractAddress,
          contractAbi,
          chainProvider
        );
        updateReader(contractReader);
      }
    };
    setupReader();
  }, [contractName, ProxyContracts, desiredChainId, chainUrls]);

  return { reader, writer };
}
