import { Bayc1 } from '../images'
import moment from 'moment/moment'
import { useReducer } from 'react'
import { BigNumber } from 'ethers'

const initialState = {
  title: "",
  description: "",
  imageUrl: "",
  upVotes: BigNumber.from(0),
  id: BigNumber.from(0),
  clickUrl: "",
  startDate: "",
  endDate: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "update":
      return { ...state, [action.key]: action.value };
    case "replace":
      return {...action.obj};
  }
};

export default function useItemObj(){
  const [itemObj, dispatch] = useReducer(reducer, initialState);
  return [itemObj, dispatch];
}