import {
  Button,
  Heading,
  Image,
  LinkBox,
  LinkOverlay,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { TriangleUpIcon } from "@chakra-ui/icons";
import { useContract, useExecuteOrConnect } from "../hooks";
import { useContext, useEffect } from "react";
import MixpanelContext from "../contexts/MixpanelContext";

export default function CarouselCard(props) {
  const { title, description, imageUrl, upVotes, clickUrl, id } = props;
  const { writer } = useContract("LeaderBoard");
  const { mp } = useContext(MixpanelContext);
  const cardData = { title, description, imageUrl, upVotes, clickUrl, id };

  const updateWantsToUpvote = useExecuteOrConnect(async () => {
    mp.track("Card Upvote", cardData);
    return await writer.upVoteItem(id);
  }, writer);

  useEffect(() => {
    if (mp && id) {
      mp.track("Card Shown", cardData);
    }
  }, [mp, id]);

  return (
    <>
      <LinkBox>
        <VStack
          spacing={2}
          w={250}
          align={"start"}
          boxShadow={"2xl"}
          rounded={"md"}
          sx={{ transition: "all .4s ease-in-out" }}
          _hover={{ boxShadow: "dark-lg", transform: "scale(1.1)" }}
        >
          <Image
            src={imageUrl}
            fallback={<Skeleton w={250} h={250} speed={5}></Skeleton>}
            objectFit="cover"
            boxSize="250px"
            w={"full"}
          />
          <VStack spacing={4} p={6} align={"start"} w={"full"}>
            <LinkOverlay
              href={clickUrl}
              isExternal={true}
              w={"full"}
              onClick={() => {
                mp.track("Card Clicked", cardData);
              }}
            >
              <Heading as="h2" size="md" noOfLines={2}>
                {title}
              </Heading>
            </LinkOverlay>
            <Text w={"full"} noOfLines={8}>
              {description}
            </Text>
            <Button
              colorScheme={"brand"}
              leftIcon={<TriangleUpIcon />}
              onClick={updateWantsToUpvote}
            >
              Upvote {upVotes?.toNumber()}
            </Button>
          </VStack>
        </VStack>
      </LinkBox>
    </>
  );
}
